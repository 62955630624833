@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600);


.buttonContainer{
	margin-top:50px;
	text-align:left;
}



/* BUTTON CSS
------------------------------------------- */

a.button{
  display:inline-block;
	
	font:normal normal 300 0.7em 'Open Sans';
	text-decoration:none;	
	
	color:rgba(28, 190, 131, 1);
	background-color:transparent;
	border:1px solid rgba(28, 190, 131, 1);
	border-radius:100px;
	
	padding: .1em .6em;
	
			
	background-size: 200% 100%;	
	background-image: linear-gradient(to right, transparent 50%, rgba(28, 190, 131, 1) 50%);
	transition: background-position .3s cubic-bezier(0.19, 1, 0.22, 1) .1s, color .5s ease 0s, background-color .5s ease;
}

a.button:hover{
	color:rgba(255, 255, 255, 1);
	background-color:rgba(28, 190, 131, 1);
	background-position: -100% 100%;
}