@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
    box-sizing: border-box;
    padding: 0px;
    margin:0;
}
.App{
    background:#e4e4e4;
    width: 100%;
    height:100vh;
    background-position: center;
    background-size: cover;
  

}
.Navigation{
    display:-webkit-flex;
    display:flex;
    width:100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    height: 20vh;
}
.Nav__logo{
    width:50px;
    height: auto;
    padding: 60px;
}
.Nav__logo img{
    width:50px;
    height: auto;
    border-radius: 15%;
}
.Nav__items{
    padding: 60px;
}
.Nav__items ul{
    list-style: none;
}
.Nav__items li{
 color:white;
}
.Content__container{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    height: 80vh;
}
.Content__text{
    color:rgb(70, 70, 70);
    font-size:50px;
    width: 500px;
    margin-left: 150px;
   
}
.green{
    color:rgba(28, 190, 131, 1);
}
.Content__image img{
    max-width:900px;
    height: auto;
    margin-right:60px;
}
.txt-type > .txt {
    border-right: 0.2rem solid #777;
  }
  @media (max-width:1024px){
    .Content__container{
        -webkit-flex-direction: column;
                flex-direction: column;
        height:80vh;
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly;
    }
    .Content__text{
        color:rgb(136, 136, 136);
        font-size:30px;
        width: 300px;
       margin-left: 0;
    }
    .Content__image img{
        max-width:300px;
        height: auto;
        margin-right:60px;
    }
  }
.buttonContainer{
	margin-top:50px;
	text-align:left;
}



/* BUTTON CSS
------------------------------------------- */

a.button{
  display:inline-block;
	
	font:normal normal 300 0.7em 'Open Sans';
	text-decoration:none;	
	
	color:rgba(28, 190, 131, 1);
	background-color:transparent;
	border:1px solid rgba(28, 190, 131, 1);
	border-radius:100px;
	
	padding: .1em .6em;
	
			
	background-size: 200% 100%;	
	background-image: linear-gradient(to right, transparent 50%, rgba(28, 190, 131, 1) 50%);
	transition: background-position .3s cubic-bezier(0.19, 1, 0.22, 1) .1s, color .5s ease 0s, background-color .5s ease;
}

a.button:hover{
	color:rgba(255, 255, 255, 1);
	background-color:rgba(28, 190, 131, 1);
	background-position: -100% 100%;
}
*{
    box-sizing: border-box;
    margin:0;
}
.container{
    
    width: 100%;
    background-color: #0B1D26;
    font-family: 'Roboto', sans-serif;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.hero{
    font-family: 'Abhaya Libre', serif;
    font-weight: 300;
    background:linear-gradient(rgba(0,0,0,0), rgba(11,28,37,1)),url(/static/media/mobile_hero.d25c0322.jpg);
    width:100%;
    height: 80vh;
    background-position: center;
    background-size: cover;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.hero__img{
    width:100%;
    text-align: center;
}
.hero__img img{
    width:10%;
    border-radius: 25px;
    height: auto;   
}
.hero__txt{
text-align: center;
padding: 20px;
font-size: 45px;
color:white;
font-weight: 700;
}
.hero__txt p:first-child{
color:#FBD784;
}
.content{
    width:75%;
}
.mobile__info{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    color:#FBD784;
    font-size: 44px;
    padding-top: 80px;
    padding-bottom: 80px;
}
.section1{
    padding: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    color:white;
}
.section1__textblock{
    width:50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    
}

.section1__line{
    width:40px;
    height: 2px;
    background-color:#FBD784;
}
.section1__title{
    font-size:64px;
}
.section1__text{
    font-size:18px;
    width: 80%;
    font-weight: 300;
}
.section1__text p {
    padding-bottom: 10px;
}
.section1__img{
    width:50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: right;
}
.section1__img img{
    width:100%;
}

.section__left,.section__left__two{
    padding: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    color:white;
    margin-bottom: 100px;
}
.section__left__textblock{
    width:50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    
}

.section__left__text{
    text-align: right;
    font-size:18px;
    width: 80%;
    font-weight: 300;
}
.section__left__text p {
    padding-bottom: 10px;
    font-size: 20px;
}
.section__left__text h3 {
    padding-bottom: 10px;
    color:#FBD784;
    font-size: 30px;
}
.section__left__img{
    width:50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: left;
}
.section__left__img img{
    width:100%;
}
.section__left__text__item{
    padding-bottom: 50px;
}
.section__left__text__item ul{
    list-style:none;
    font-size: 20px;
    direction: rtl;
    }
    .section__left__text__item ul li::before{
        content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color:#FBD784; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-right: -1em; /* Also needed for space (tweak if needed) */
    }
.section__right,.section__right__two{
    padding: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    color:white;
    margin-bottom: 100px;
}
.section__right__textblock{
    width:50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    
}

.section__right__text{
    text-align: left;
    font-size:18px;
    width: 80%;
    font-weight: 300;
}
.section__right__text p {
    padding-bottom: 10px;
    font-size: 20px;
}
.section__right__text h3 {
    padding-bottom: 10px;
    color:#FBD784;
    font-size: 30px;
}
.section__right__img{
    width:50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: left;
}
.section__right__img img{
    width:100%;
}
.section__right__text__item{
    padding-bottom: 50px;
}
.section__right__text__item ul{
list-style:none;
font-size: 20px;
}
.section__right__text__item ul li::before{
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color:#FBD784; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
}
.section3{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    color:white;
    padding:60px;
}
.section3__block{
    border:2px solid #FBD784;
    -webkit-flex:0.31 1;
            flex:0.31 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content:flex-start;
            justify-content:flex-start;
    -webkit-align-items: center;
            align-items: center;
    padding:35px;
   
}
.section3__blockTitle{
    text-align: center;
    font-size:34px;
    height: 130px;
    padding-bottom: 20px;
}
.section3__blockText{
    text-align: left;
    font-size:16px;
    font-weight: 300;
}
@media (max-width:1024px){
    .content{
        width: 90%;
    }
    .section1,.section__left,.section__right.section__left__two,.section__right__two{
        -webkit-flex-direction: column;
                flex-direction: column;
        width: 100%;
    }
    .section__left__img,.section__right__img  {
        width: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        text-align: center;
    }
    .section1__textblock,.section1__img{
        width: 100%;
    }
    .section__left__textblock,.section__right__textblock {
        width: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        margin-top:50px;
    }
}
